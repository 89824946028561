export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        headerText: {
            type: String,
            default: 'Default Header',
        },
        bodyText: {
            type: String,
            default: 'Default body content',
        },
    },
    emits: ['close', 'action'],
    methods: {
        closeModal() {
            this.$emit('close');
        },
    },
};
import { ref, provide, inject, computed, getCurrentInstance } from 'vue'
import useBrowser from '../../../utilities/use-browser.js';
import FloatingVue from 'floating-vue'
import i18nService from '../../../services/i18n-service.js';

export default {
    setup(props, { attrs }) {

        const instance = getCurrentInstance();
        const app = instance.appContext.app;

        app.use(FloatingVue)

        const languageData = inject('$languageData');

        const languageDataCommon = computed(() => languageData.value.common);
        const languageDataHow = computed(() => languageData.value.how);


        const language = inject('$language');
        const extensionLabel = inject('$extensionLabel')


        const {
            useComputedExtensionLabel,
            isMobile,
        } = useBrowser()

        const computedExtensionLabel = useComputedExtensionLabel()

        const menuOpen = ref()

        const menu = computed(() => [
            {
                copy: languageDataCommon.value.homeFooterLink,
                name: 'home',
                hash: ''
            },
            {
                copy: languageDataCommon.value.howItWorksNavButton,
                name: 'how-it-works',
                hash: '#how-it-works'
            },
            {
                copy: languageDataCommon.value.pointsNavButton,
                name: 'points'
            },
            {
                copy: 'Add to Browser',
                name: 'add-to-browser'
            },
            {
                copy: languageDataCommon.value.faq,
                name: 'faq-general'
            }
        ])


        const subMenu = ref([
            {
                copy: languageDataCommon.value.languages[language.value] || languageDataCommon.value.languages.en,
                icon: 'icon-language',
                type: 'lang'
            },
            {
                copy: extensionLabel,
                href: languageDataHow.heroDownloadLink,
                disable: isMobile,
                color: 'text-JauntrPrimary-500'
            }
        ])

        const goToHash = (item) => {
            if (item.hash) {
                location.hash = item.hash
            }
        }

        const handleClick = (item) => {

            if (item.href) {
                window.location.href = item.href;
            }

            if (item.type === 'lang') {
                toggleLangDialog()
            }

            menuOpen.value = !menuOpen.value;

        }

        const toggleMenu = () => {
            menuOpen.value = !menuOpen.value
        }

        const showLangDialog = ref()

        const toggleLangDialog = () => {
            showLangDialog.value = !showLangDialog.value
        }

        const switchLang = async (lang) => {
            language.value = lang
            languageData.value = await i18nService.index(lang);
            localStorage.setItem('language', lang);
            toggleLangDialog()
            window.location.reload()
        }

        return {
            menu,
            menuOpen,
            subMenu,
            showLangDialog,
            goToHash,
            handleClick,
            toggleMenu,
            languageDataCommon,
            switchLang,
            language
        }

    },
    inheritAttrs: false,

};
